.ContextMenu {
    position:absolute;
    z-index:15;
    margin: 0;
    padding: 8px;
    background: #f5f5f5;
    border: 1px solid #979797;
    box-shadow: 2px 2px 2px #999;
    
}
.ContextMenu_row{
    font-size: 15px;
}
.ContextMenu_row:hover{
    cursor: pointer;
}

.show {
    display:block;
}
.hide {
    display:none;
}